<template>
  <div class="page">
    <div class="page-header">
      <div class="page-title">
        <img src="../assets/img/年度重点项目.png" alt="" />
      </div>
      <div class="page-header-middle">
        <div class="page-header-left">
          <img src="../assets/img/大唐萌宫.png" alt="" />
        </div>
        <div class="page-header-right">
          <div><img src="../assets/img/中国版“迪士尼”.png" alt=""></div>
          <p>
            《大唐萌宫》是目前国内首家国风儿童主题沉浸式文旅项目。依托洛阳文化元素和河南广电优势资源，以隋唐洛阳城为构造寄出，以儿童视角复活传统文化，用诗词经典为当代儿童构建一个奇幻二次元世界。是此前洛阳中心打造的“儿童版唐宫小姐姐”和“唐宫小萌宝”的升级版。该项目完全自主研发并已注册商标和版权，倾力打造的“中国版迪士尼”。内容涵盖少儿影视、少儿剧场、少儿剧本杀、少儿研学、少儿文创等为一体的综合沉浸式文旅项目。
          </p>
        </div>
      </div>
      <div class="page-header-footer">
        <h3>IP设定</h3>
        <div class="page-header-IP">
          <div class="page-header-IPsingle">
            <img src="../assets/img/萌乐天.png" alt="" />
            <p>萌乐天</p>
          </div>
          <div class="page-header-IPsingle">
            <img src="../assets/img/萌龙龙.png" alt="" />
            <p>萌龙龙</p>
          </div>
          <div class="page-header-IPsingle">
            <img src="../assets/img/萌唐唐.png" alt="" />
            <p>萌唐唐</p>
          </div>
          <div class="page-header-IPsingle">
            <img src="../assets/img/萌小白.png" alt="" />
            <p>萌太白</p>
          </div>
          <div class="page-header-IPsingle">
            <img src="../assets/img/萌小乖.png" alt="" />
            <p>萌小乖</p>
          </div>
          <div class="page-header-IPsingle">
            <img src="../assets/img/萌小乙.png" alt="" />
            <p>萌小乙</p>
          </div>
        </div>
      </div>
    </div>
    <div class="page-main">
      <div class="page-main-first">
        <h4>场馆展示</h4>
        <div class="page-main-firstimg commoncss">
          <img src="../assets/img/场馆展示1.png" alt="" />
          <img src="../assets/img/场馆展示2.png" alt="" />
          <img src="../assets/img/场馆展示3.png" alt="" />
        </div>
      </div>
      <div class="page-main-common">
        <h4>萌宫天街</h4>
        <div class="page-main-secondimg commoncss">
          <img src="../assets/img/萌宫天街1.png" alt="" />
          <img src="../assets/img/萌宫天街2.png" alt="" />
          <img src="../assets/img/萌宫天街3.png" alt="" />
        </div>
        <p>多媒体效果：+墙体彩绘+美称置景</p>
        <p>
          玩法效果：与彩绘内容一致，街景造型，当玩家经过有投影区域时，场景突然坍塌，次元兽跑出，发出嘲笑并消失
        </p>
      </div>
      <div class="page-main-common">
        <h4>诗仙会客厅</h4>
        <div class="page-main-thirdimg commoncss">
          <img src="../assets/img/诗仙会客厅1.png" alt="" />
          <img src="../assets/img/诗仙会客厅2.png" alt="" />
          <img src="../assets/img/诗仙会客厅3.png" alt="" />
        </div>
        <p>多媒体效果：+墙体彩绘+美称置景</p>
        <p>玩法效果：与彩绘内容一致，街景造型，与玩家配合互动</p>
      </div>
      <div class="page-main-common">
        <h4>次元兽决战</h4>
        <div class="page-main-fivethimg commoncss">
          <img src="../assets/img/次元兽决战1.png" alt="" />
          <img src="../assets/img/次元兽决战2.png" alt="" />
          <img src="../assets/img/次元兽决战3.png" alt="" />
        </div>
        <p>多媒体效果：+墙体彩绘+美称置景+空间切合</p>
        <p>玩法效果：三维动态场景，街景造型，与玩家配合互动</p>
      </div>
      <div class="page-main-common">
        <h4>智能手工课堂</h4>
        <div class="page-main-fourthimg commoncss" >
          <img src="../assets/img/智能手工课堂1.png" alt="" />
          <img src="../assets/img/智能手工课堂2.png" alt="" />
        </div>
        <p>多媒体效果：+墙体彩绘+美称置景+空间切合</p>
        <p>玩法效果：三维动态场景，街景造型，与玩家配合互动</p>
      </div>
      <div class="page-main-common">
        <h4>萌唐唐诗会</h4>
        <div class="page-main-fivethimg commoncss">
          <img src="../assets/img/萌唐唐诗会1.png" alt="" />
          <img src="../assets/img/萌唐唐诗会2.png" alt="" />
          <img src="../assets/img/萌唐唐诗会3.png" alt="" />
        </div>
        <p>多媒体效果：+墙体彩绘+美称置景+空间切合</p>
        <p>玩法效果：三维动态场景，街景造型，与玩家配合互动</p>
      </div>
      <div class="page-main-common">
        <h4>剧本杀馆</h4>
        <div>
          <!-- <img src="../assets/img/.png" alt="" />
          <img src="../assets/img/.png" alt="" />
          <img src="../assets/img/.png" alt="" /> -->
        </div>
        <p>多媒体效果：+墙体彩绘+美称置景+空间切合</p>
        <p>玩法效果：三维动态场景，街景造型，与玩家配合互动</p>
      </div>
    </div>
  </div>
</template>
<style scoped>
.page-header{
  width: 100%;
}
.page-header img{
  width: 100%;
  height: auto;
}
.page-header-middle{
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin:3rem auto;
    padding: 2rem;
    background-color: #f7f8f8;
}
.page-header-left{
    width: 48%;
}
.page-header-left img{
    width: 100%;
    height: auto;
}
.page-header-right{
    width: 48%;
    text-align: center;
}
.page-header-right img {
  width: 70%;
  height: auto;
}
.page-header-right p{
  text-indent: 3rem;
  color: #161616;
  line-height: 2rem;
}
.page-header-footer{
  width: 90%;
  margin: auto;
  padding: 2rem;
  background-color: #f7f8f8;
}
.page-main{
    background: url("../assets/img/场馆展示后蓝背景.png");
}
.page-main h4{
  text-align: center;
  font-size: 3rem;
  letter-spacing: 4px;
  margin:0 0 2rem 0;
}
.page-header-IP{
 display: flex;
 justify-content: space-between;
}
.page-header-IPsingle{
    width: 16%;
    height: auto;
    text-align: center;
}
.page-main-firstimg{
  margin: 2rem;
}
.page-main-firstimg img:nth-child(1){
  width: 28%;
}
.page-main-firstimg img:nth-child(2){
  width: 40%;
}
.page-main-firstimg img:nth-child(3){
  width: 30%;
}
.page-main-first{
  margin: 1rem;
  padding: 2rem;
}
.page-main-first h4 {
  color: #ffffff;
}
.page-main-common{
  margin: 1rem;
  padding: 2rem;
  background-color: #f1f3ff;
}

.commoncss{
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;
}
.page-main-secondimg img:nth-child(1){
  width: 24%;
}
.page-main-secondimg img:nth-child(2){
  width: 48%;
}
.page-main-secondimg img:nth-child(3){
  width: 26%;
}

.page-main-thirdimg img:nth-child(1){
  width: 30%;
}
.page-main-thirdimg img:nth-child(2){
  width: 30%;
}
.page-main-thirdimg img:nth-child(3){
  width: 30%;
}

.page-main-fivethimg img:nth-child(1){
  width: 30%;
}
.page-main-fivethimg img:nth-child(2){
  width: 30%;
}
.page-main-fivethimg img:nth-child(3){
  width: 30%;
}
.page-main-fourthimg{
  margin: 1rem 10rem;
}
.page-main-fourthimg img:nth-child(1){
  width: 40%;
}
.page-main-fourthimg img:nth-child(2){
  width: 40%;
}

.page-main-sixthimg{

}
.page-main-sixthimg img:nth-child(1){
  width: 30%;
}
.page-main-sixthimg img:nth-child(2){
  width: 30%;
}
.page-main-sixthimg img:nth-child(3){
  width: 30%;
}
</style>
